// https://www.gatsbyjs.org/docs/adding-tags-and-categories-to-blog-posts/#add-tags-to-your-markdown-files

import React from 'react'
import { Link, graphql } from 'gatsby'

import { kebabCase } from 'lodash'

import Layout from '../components/layout'
import SEO from '../components/seo'
const RoastersPage = ({ data }) => {
  const allRoasters = data.allMarkdownRemark.group
  const sortedRoasters = allRoasters.sort(
    (first, second) => second.totalCount - first.totalCount
  )

  return (
    <Layout>
      <SEO
        title="Snoffeecob Blogs by Roaster"
        description="Snoffeecob Blogs by Origin"
      />
      <div className="blog-tags">
        <h1>Snoffeecob reviews by roaster</h1>
        <ul className="tags">
          {sortedRoasters.map(roaster => (
            <li key={roaster.fieldValue}>
              <Link
                to={`/roasters/${kebabCase(roaster.fieldValue)}/`}
                className="tag"
              >
                {roaster.fieldValue} ({roaster.totalCount})
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </Layout>
  )
}

export default RoastersPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___roaster) {
        fieldValue
        totalCount
      }
    }
  }
`
